<template>
	<div id="footer">
		<!-- 底部栏顶部 电脑端 -->
		<div class="cancelRow visible-lg visible-md container-fluid">
			<div class="row top">
				<!-- 底部栏左侧 -->
				<div class="footer-left">
					<img src="http://official.feizhisoft.com/picture/logo_1.png" width="213" height="43.13" />
					<h4>苏州飞智信息科技有限公司</h4>
				</div>
				<!--底部栏左侧  公司介绍 -->
				<div class="footer-middle">
					<div class="company_introduce">
						<!-- 导航内容 -->
						<h4>网站目录</h4>
						<ul class="header-nav-wrapper">
							<li v-for="(item, index) in navList" :key="index" :class="[index == navIndex ? 'active liStyle' : 'liStyle']" @click="navClick(index, item.name)">
								<router-link :to="item.path">
									<span> {{ item.name }}</span>
									<img src="http://official.feizhisoft.com/icon/arrow.png" width="12" height="12" />
								</router-link>
							</li>
						</ul>
					</div>
				</div>
				<!-- 联系方式 -->
				<div class="footer-right">
					<div class="company_contact">
						<h4>联系详情</h4>
						<div>
							<img src="http://official.feizhisoft.com/icon/telephone.png" alt="" width="14" height="14" />
							<!-- <span class="">服务热线：0512-62601803</span> -->
							<img style="margin-left: 16px" src="http://official.feizhisoft.com/icon/pc_telephone.png" alt="" width="178" height="19" />
						</div>
						<div>
							<img src="http://official.feizhisoft.com/icon/email.png" alt="" width="14" height="14" />
							<!-- <span class="">postmaster@feizhisoft.com</span> -->
							<img style="margin-left: 16px" src="http://official.feizhisoft.com/icon/pc_address.png" alt="" width="186" height="18" />
						</div>
						<div>
							<img src="http://official.feizhisoft.com/icon/address.png" alt="" width="14" height="14" />
							<span class=""> 苏州工业园区仁爱路西交大科技园D1幢509-510 </span>
						</div>
						<!-- <div class="lastdiv">
							<span class=""> 张家港市国泰北路高新技术创业服务中心A-411 </span>
						</div> -->
					</div>
				</div>
			</div>
			<div class="bottom-footer">
				<span class="bottom-left"> COPYRIGHT © 2012-2020 苏州飞智信息科技有限公司 版权所有 </span>
				<span class="bottom-right"> <a href="https://beian.miit.gov.cn/" style="color: #c8cacf"> 备案号：苏ICP备15008392号-1</a> </span>
			</div>
		</div>
		<!-- 中等屏幕适配 -->
		<div class="cancelRow container-fluid visible-sm">
			<div class="top">
				<!-- 底部栏左侧 -->
				<div class="footer-left">
					<img src="http://official.feizhisoft.com/picture/logo_1.png" width="200" height="20" />
					<h4>苏州飞智信息科技有限公司</h4>
				</div>
				<!--底部栏左侧  公司介绍 -->
				<div class="footer-middle">
					<div class="company_introduce">
						<!-- 导航内容 -->
						<h4>网站目录</h4>
						<ul class="header-nav-wrapper">
							<li v-for="(item, index) in navList" :key="index" :class="[index == navIndex ? 'active liStyle' : 'liStyle']" @click="navClick(index, item.name)">
								<router-link :to="item.path">
									<span> {{ item.name }}</span>
									<img src="http://official.feizhisoft.com/icon/arrow.png" width="12" height="12" />
								</router-link>
							</li>
						</ul>
					</div>
				</div>
				<!-- 联系方式 -->
				<div class="footer-right">
					<div class="company_contact">
						<h4>联系详情</h4>
						<div>
							<img src="http://official.feizhisoft.com/icon/telephone.png" alt="" width="14" height="14" />
							<!-- <span class="">服务热线：0512-62601803</span> -->
							<img style="margin-left: 16px" src="http://official.feizhisoft.com/icon/pc_telephone.png" alt="" width="178" height="19" />
						</div>
						<div>
							<img src="http://official.feizhisoft.com/icon/email.png" alt="" width="14" height="14" />
							<!-- <span class="">postmaster@feizhisoft.com</span> -->
							<img style="margin-left: 16px" src="http://official.feizhisoft.com/icon/pc_address.png" alt="" width="186" height="18" />
						</div>
						<div>
							<img src="http://official.feizhisoft.com/icon/address.png" alt="" width="14" height="14" />
							<span class=""> 苏州工业园区仁爱路西交大科技园D1幢509-510 </span>
						</div>
						<!-- <div class="lastdiv">
							<span class=""> 张家港市国泰北路高新技术创业服务中心A-411 </span>
						</div> -->
					</div>
				</div>
				<!-- 备案号 -->
				<div class="cancelRow bottom-footer">
					<span class="bottom-left"> COPYRIGHT © 2012-2020 苏州飞智信息科技有限公司 版权所有 </span>
					<span class="bottom-right"> <a href="https://beian.miit.gov.cn/" style="color: #c8cacf"> 备案号：苏ICP备15008392号-1 </a></span>
				</div>
			</div>
		</div>
		<!-- 手机导航 -->
		<div class="cancelRow container-fluid visible-xs">
			<div class="top">
				<!-- 底部栏左侧 -->
				<div class="footer-left">
					<img src="http://official.feizhisoft.com/picture/logo_1.png" width="200"  height="40.5"/>
					<h4>苏州飞智信息科技有限公司</h4>
				</div>
				<!--底部栏左侧  公司介绍 -->
				<div class="footer-middle">
					<div class="company_introduce">
						<!-- 导航内容 -->
						<h4>网站目录</h4>
						<ul class="header-nav-wrapper">
							<li v-for="(item, index) in navList" :key="index" :class="[index == navIndex ? 'active liStyle' : 'liStyle']" @click="navClick(index, item.name)">
								<router-link :to="item.path">
									<span> {{ item.name }}</span>
									<img src="http://official.feizhisoft.com/icon/arrow.png" width="12" height="12" />
								</router-link>
							</li>
						</ul>
					</div>
				</div>
				<!-- 联系方式 -->
				<div class="footer-right">
					<div class="company_contact">
						<h4>联系详情</h4>
						<div>
							<img src="http://official.feizhisoft.com/icon/telephone.png" alt="" width="14" height="14" />
							<!-- <span class="">服务热线：0512-62601803</span> -->
							<img style="margin-left: 16px" src="http://official.feizhisoft.com/icon/pc_telephone.png" alt="" width="178" height="19" />
						</div>
						<div>
							<img src="http://official.feizhisoft.com/icon/email.png" alt="" width="14" height="14" />
							<!-- <span class="">postmaster@feizhisoft.com</span> -->
							<img style="margin-left: 16px" src="http://official.feizhisoft.com/icon/pc_address.png" alt="" width="186" height="18" />
						</div>
						<div>
							<img src="http://official.feizhisoft.com/icon/address.png" alt="" width="14" height="14" />
							<span class=""> 苏州工业园区仁爱路西交大科技园D1幢509-510 </span>
						</div>
						<!-- <div class="lastdiv">
							<span class=""> 张家港市国泰北路高新技术创业服务中心A-411 </span>
						</div> -->
					</div>
				</div>
				<!-- 备案号 -->
				<div class="cancelRow bottom-footer">
					<span class="bottom-left"> COPYRIGHT © 2012-2020 苏州飞智信息科技有限公司 版权所有 </span>
					<span class="bottom-right"><a href="https://beian.miit.gov.cn/">备案号：苏ICP备15008392号-1</a></span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "FooterView",
	data() {
		return {
			navIndex: sessionStorage.getItem("navIndex") ? sessionStorage.getItem("navIndex") : 0,
			navList: [
				// {
				// 	name: "关于飞智",
				// 	path: "/aboutUs",
				// },
				{
						name: "AI解决方案",
						pathname: "AI",
						path: "/artificialIntelligence",
						children: [],
					},
				{
					name: "数字化方案",
					path: "/businessSegments",
					children: [
						{
							name: "数字化解决方案",
							path: "/businessSegments/digitalSolutions",
							pathname: "digitalSolutions",
						},
						// {
						// 	name: "大数据中台",
						// 	path: "/businessSegments/bigData",
						// 	pathname: "bigData",
						// },
						// {
						// 	name: "人工智能",
						// 	path: "/businessSegments/artificialIntelligence",
						// 	pathname: "artificialIntelligence",
						// },
						{
							name: "移动应用",
							path: "/businessSegments/carEmbedded",
							pathname: "carEmbedded",
						},
						{
							name: "定制化开发",
							path: "/businessSegments/customization",
							pathname: "customization",
						},
						// {
						// 	name: "微信开发",
						// 	path: "/software/smartTown",
						// },
						// {
						// 	name: "小程序",
						// 	path: "/software/bigData",
						// },
						// {
						// 	name: "网站开发",
						// 	path: "/software/smartTown",
						// },
						// {
						// 	name: "运维服务",
						// 	path: "/software/bigData",
						// },
					],
				},
				// {
				// 	name: "解决方案",
				// 	path: "/solveMethod",
				// 	children: [],
				// },
				{
						name: "热销产品",
						pathname: "digitalProducts",
						path: "/digitalProducts",
						children: [
							{
								name: "双因子身份认证系统",
								path: "/digitalProducts",
								pathname: "bigData",
							},	{
								name: "数字化干部管理系统",
								path: "digitalProducts/cadreSystem",
								pathname: "cadreSystem",
							},	{
								name: "商会宝 商会协会系统",
								path: "/digitalProducts/SXHProducts",
								pathname: "SXHProducts",
							},	{
								name: "数字化后勤管理系统",
								path: "/digitalProducts/HQProducts",
								pathname: "HQProducts",
							},
						],
					},
				{
					name: "服务案例",
					path: "/serviceCategory",
					children: [],
				},
				{
					name: "诚聘英才",
					path: "/recruitPeople",
					children: [],
				},
				{
					name: "关于我们",
					path: "/contactUs",
					children: [],
				},
			],
		};
	},
};
</script>
<style scoped>
#footer {
	color: #fff;
	background: #181f35;
}

.visible-lg .top {
	display: flex;
	margin: 0 auto;
	padding-left: -15px;
	/* padding-bottom: 20px; */
}

.visible-lg .top .footer-left {
	width: 38%;
	margin-top: 66px;
	padding-left: 10%;
}

.visible-lg .top .footer-left > img {
	margin-bottom: 20px;
}

.visible-lg .top .footer-left > h4 {
	height: 24px;
	font-size: 18px;
	font-family: Microsoft YaHei;
	line-height: 24px;
	color: #c8cacf;
}

.visible-lg .top .footer-middle {
	display: flex;
	width: 21%;
	margin-right: 12%;
}

.visible-lg .top .footer-middle .company_introduce {
	margin-top: 54px;
	padding-left: -15px;
}

.visible-lg .top .footer-middle .company_introduce > h4 {
	font-size: 18px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	line-height: 33px;
	color: #ffffff;
}

.visible-lg .top .footer-middle .company_introduce .header-nav-wrapper {
	margin-top: 23px;
	margin-bottom: 20px;
}

.visible-lg .top .footer-middle .company_introduce .header-nav-wrapper .liStyle {
	color: #c8cacf;
	padding-top: 15px;
	padding-right: 9px;
	padding-bottom: 15px;
	border-bottom: 2px solid #353b55;
}

.visible-lg .top .footer-middle .company_introduce .header-nav-wrapper .liStyle > a {
	display: flex;
	align-items: center;
}

.visible-lg .top .footer-middle .company_introduce .header-nav-wrapper .liStyle > a > span {
	width: 270px;
	height: 20px;
	font-size: 15px;
	font-family: Microsoft YaHei;
	line-height: 20px;
	color: #c8cacf;
}

.visible-lg .top .footer-right {
	margin-right: 8%;
}

.visible-lg .top .footer-right .company_contact {
	margin-top: 54px;
}

.visible-lg .top .footer-right .company_contact > h4 {
	display: inline-block;
	margin-bottom: 42px;
	width: 72px;
	height: 24px;
	font-size: 18px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	line-height: 33px;
	color: #ffffff;
}

.visible-lg .top .footer-right .company_contact div {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 24px;
}

.visible-lg .top .footer-right .company_contact div span {
	margin-left: 17px;
	height: 19px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	line-height: 19px;
	color: #c8cacf;
}

.visible-lg .top .footer-right .company_contact .lastdiv > span {
	margin-left: 30px;
	height: 19px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	line-height: 19px;
	color: #c8cacf;
}

.visible-lg .bottom-footer {
	display: flex;
	position: relative;
	height: 50px;
	background-color: #141929;
	/* justify-content: space-between; */
}

.visible-lg .bottom-footer .bottom-left {
	position: absolute;
	left: 8%;
	top: 50%;
	transform: translate(0, -50%);
	width: 413px;
	height: 19px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	line-height: 19px;
	color: #c8cacf;
}

.visible-lg .bottom-footer .bottom-right {
	position: absolute;
	right: 10%;
	top: 50%;
	transform: translate(0, -50%);
	width: 204px;
	height: 19px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	line-height: 19px;
	color: #c8cacf;
}

/* --------------------- */
.visible-xs .top {
	/* display: flex; */
	margin: 0 auto;
	padding-left: -15px;
	/* padding-bottom: 20px; */
}

.visible-xs .top .footer-left {
	width: 38%;
	margin-top: 66px;
	padding-left: 12%;
}

.visible-xs .top .footer-left > img {
	margin-bottom: 20px;
}

.visible-xs .top .footer-left > h4 {
	width: 300px;
	height: 24px;
	font-size: 16px;
	font-family: Microsoft YaHei;
	line-height: 24px;
	color: #c8cacf;
}

.visible-xs .top .footer-middle {
	display: flex;
	width: 21%;
	margin-left: 13%;
	/* margin: 0 auto; */
}

.visible-xs .top .footer-middle .company_introduce {
	margin-top: 54px;
	padding-left: -15px;
}

.visible-xs .top .footer-middle .company_introduce > h4 {
	font-size: 18px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	line-height: 33px;
	color: #ffffff;
}

.visible-xs .top .footer-middle .company_introduce .header-nav-wrapper {
	margin-top: 43px;
}

.visible-xs .top .footer-middle .company_introduce .header-nav-wrapper .liStyle {
	color: #c8cacf;
	padding-top: 15px;
	padding-right: 9px;
	padding-bottom: 15px;
	border-bottom: 2px solid #353b55;
}

.visible-xs .top .footer-middle .company_introduce .header-nav-wrapper .liStyle > a {
	display: flex;
	align-items: center;
}

.visible-xs .top .footer-middle .company_introduce .header-nav-wrapper .liStyle > a > span {
	width: 270px;
	height: 20px;
	font-size: 15px;
	font-family: Microsoft YaHei;
	line-height: 20px;
	color: #c8cacf;
}

.visible-xs .top .footer-right {
	/* width: 100%; */
	display: block;
	/* margin: 0 auto; */
	margin-left: 13%;
}

.visible-xs .top .footer-right .company_contact {
	margin-top: 54px;
}

.visible-xs .top .footer-right .company_contact > h4 {
	display: inline-block;
	margin-bottom: 42px;
	width: 72px;
	height: 24px;
	font-size: 18px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	line-height: 33px;
	color: #ffffff;
}

.visible-xs .top .footer-right .company_contact div {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 24px;
}

.visible-xs .top .footer-right .company_contact div span {
	margin-left: 17px;
	height: 19px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	line-height: 19px;
	color: #c8cacf;
}

.visible-xs .top .footer-right .company_contact .lastdiv > span {
	margin-left: 30px;
	height: 19px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	line-height: 19px;
	color: #c8cacf;
}

.visible-xs .bottom-footer {
	/* display: flex; */
	/* position: relative; */
	width: 100%;
	height: 50px;
	background-color: #141929;
	/* justify-content: space-between; */
}

.visible-xs .bottom-footer .bottom-left {
	display: block;
	margin: 0 auto;
	/* margin-left: 0.5%; */
	/* background-color: #141929; */
	/* padding-right: -15px;
	padding-left: -15px; */
	width: 348px;
	height: 19px;
	font-size: 12px;
	font-family: Microsoft YaHei;
	line-height: 19px;
	color: #c8cacf;
}

.visible-xs .bottom-footer .bottom-right {
	display: block;
	margin: 0 auto;
	width: 204px;
	height: 19px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	line-height: 19px;
	color: #c8cacf;
}

.visible-xs .bottom-footer .bottom-right a {
	display: block;
	margin: 0 auto;
	width: 204px;
	height: 19px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	line-height: 19px;
	color: #c8cacf;
}

/* ------- */
.visible-sm .top {
	/* display: flex; */
	margin: 0 auto;
	padding-left: -15px;
	/* padding-bottom: 20px; */
}

.visible-sm .top .footer-left {
	width: 38%;
	margin-top: 66px;
	/* padding-left: 20%; */
	margin-left: auto;
	margin-right: auto;
}

.visible-sm .top .footer-left > img {
	margin-bottom: 20px;
}

.visible-sm .top .footer-left > h4 {
	width: 300px;
	height: 24px;
	font-size: 16px;
	font-family: Microsoft YaHei;
	line-height: 24px;
	color: #c8cacf;
}

.visible-sm .top .footer-middle {
	display: flex;
	width: 21%;
	margin-left: 30%;
	/* margin: 0 auto; */
}

.visible-sm .top .footer-middle .company_introduce {
	margin-top: 54px;
	padding-left: -15px;
}

.visible-sm .top .footer-middle .company_introduce > h4 {
	font-size: 18px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	line-height: 33px;
	color: #ffffff;
}

.visible-sm .top .footer-middle .company_introduce .header-nav-wrapper {
	margin-top: 43px;
}

.visible-sm .top .footer-middle .company_introduce .header-nav-wrapper .liStyle {
	color: #c8cacf;
	padding-top: 15px;
	padding-right: 9px;
	padding-bottom: 15px;
	border-bottom: 2px solid #353b55;
}

.visible-sm .top .footer-middle .company_introduce .header-nav-wrapper .liStyle > a {
	display: flex;
	align-items: center;
}

.visible-sm .top .footer-middle .company_introduce .header-nav-wrapper .liStyle > a > span {
	width: 270px;
	height: 20px;
	font-size: 15px;
	font-family: Microsoft YaHei;
	line-height: 20px;
	color: #c8cacf;
}

.visible-sm .top .footer-right {
	/* width: 100%; */
	display: block;
	/* margin: 0 auto; */
	margin-left: 30%;
}

.visible-sm .top .footer-right .company_contact {
	margin-top: 54px;
}

.visible-sm .top .footer-right .company_contact > h4 {
	display: inline-block;
	margin-bottom: 42px;
	width: 72px;
	height: 24px;
	font-size: 18px;
	font-family: Microsoft YaHei;
	font-weight: bold;
	line-height: 33px;
	color: #ffffff;
}

.visible-sm .top .footer-right .company_contact div {
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 24px;
}

.visible-sm .top .footer-right .company_contact div span {
	margin-left: 17px;
	height: 19px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	line-height: 19px;
	color: #c8cacf;
}

.visible-sm .top .footer-right .company_contact .lastdiv > span {
	margin-left: 30px;
	height: 19px;
	font-size: 14px;
	font-family: Microsoft YaHei;
	line-height: 19px;
	color: #c8cacf;
}

.visible-sm .bottom-footer {
	width: 100%;
	height: 50px;
	background-color: #141929;
}

.visible-sm .bottom-footer .bottom-left {
	display: block;
	margin: 0 auto;
	width: 348px;
	height: 19px;
	font-size: 12px;
	font-family: Microsoft YaHei;
	line-height: 19px;
	color: #c8cacf;
}

.visible-sm .bottom-footer .bottom-right {
	display: block;
	margin: 0 auto;
	width: 204px;
	height: 19px;
	font-size: 14px !important;
	font-family: Microsoft YaHei;
	line-height: 19px;
	color: #c8cacf !important;
}

.visible-sm .bottom-footer .bottom-right a {
	display: block;
	margin: 0 auto;
	width: 204px;
	height: 19px;
	font-size: 14px !important;
	font-family: Microsoft YaHei;
	line-height: 19px;
	color: #c8cacf !important;
}

.cancelRow {
	padding-left: 0px !important;
	padding-right: 0px !important;
}
</style>
