<template>
	<div>
		<div v-if="isLnag">
			<Header />
		</div>
		<div v-else>
			<HeaderEn />
		</div>
		<keep-alive>
			<router-view />
		</keep-alive>
		<div v-if="isLnag">
			<Footer />
		</div>
		<div v-else>
			<FooterEn />
		</div>
		<div class="visible-lg">
			<img class="back-up" src="https://official.feizhisoft.com/picture/ywbk/back_up.png" alt="" @click="scrollToTop">
		</div> 

		<div class="visible-sm visible-xs visible-md">
			<img class="back-up" src="https://official.feizhisoft.com/picture/ywbk/back_up.png" alt="" @click="scrollToTop">
		</div> 
	</div>
</template>

<script>
	import Footer from "./Footer.vue";
	import FooterEn from "./en/Footer.vue";
	import Header from "./Header.vue";
	import HeaderEn from "./en/Header.vue";
	export default {
		name: "LayoutPage",
		components: {
			Footer,
			Header,
			HeaderEn,
			FooterEn
		},


		created() {
			console.log(this.global.language, 'created')
		},
		methods: {
			scrollToTop() {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			}
		},
		computed: {
			isLnag() {
				console.log(this.$route.path, 'this.$route.path')
				
				if(this.$route.path == '/en'){
					return false
				} 
				if (this.global.language == "chinese") {
					return true
				}
				if (this.global.language == "english") {
					return false
				}

			},
		}, 
	};
</script>

<style scoped>
	/* 当屏幕宽度大于768px ，但小于等于992px时，显示如下样式 */
	@media screen and (max-width: 992px) {
		.back-up{
			position: fixed;
			bottom: 50px;
			right: 5px !important;
			width: 40px;
			height: 40px; 
		}

	}


	 

	@media screen and (max-width: 768px) {
		
	}

.back-up{
	position: fixed;
	bottom: 180px;
	right: 30px;
}
	</style>